import React from 'react';
import AWS from 'aws-sdk';
import Helmet from 'react-helmet';
import { Header, Form, Grid, Button, Divider, Icon } from 'semantic-ui-react';

import Layout from '../components/Layout';
import '../style/contact.css';
import emailService from '../utils/email';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBot: '',
      name: '',
      email: '',
      content: '',
      sent: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      )
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value })
  }

  handleTextareaChange(e) {
    this.setState({ content: e.target.value })
  }

  onSubmit() {
    if (this.state.isBot.length === 0) {
      emailService(this.state).then(res => this.setState({ sent: true }));
    }
  }

  render() {
    return (
      <Layout>
        <Helmet
          title={`Ari Hauben Art | Contact`}
          meta={[
            { name: 'description', content: 'Contact Ari about his latest work.' },
          ]}
        />
        <Grid stackable textAlign="center">
          <Grid.Column width={7} textAlign="left">
            <Header as="h1">CONTACT ARI</Header>
            <p>
              Feel free to contact me for more information about my work, process, events, or availability.
            </p>
            <Grid className="contact-info-container">
              <Grid.Row className="contact-info-row-container">
                <Grid.Column width={2} textAlign="right">
                  <Icon name="map marker alternate" size="large"/>
                </Grid.Column>
                <Grid.Column width={14}>
                  <h5>STUDIO 413</h5><br />
                  <h5>15 Channel Center St</h5><br />
                  <h5>Boston, MA 02210</h5>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="contact-info-row-container">
                <Grid.Column width={2}>
                  <Icon name="mail" size="large"/>
                </Grid.Column>
                <Grid.Column width={14}>
                  <h5>
                    <a href="mailto:arihaubenart@gmail.com">AriHaubenArt@gmail.com</a>
                  </h5>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <div>
              <a style={{ color: "inherit" }} href="https://www.instagram.com/arihaubenart/">
                <Icon name="instagram" size="large" />
              </a>
              <a style={{ color: "inherit" }} href="https://www.facebook.com/Ari-Haubens-Art-261456753885496/">
                <Icon name="facebook" size="large" />
              </a>
            </div>
          </Grid.Column>
        </Grid>
      </Layout>
    );
  }
};
